<template>
  <v-card :loading="loading">
    <v-system-bar>Feedback nach Schulklassen</v-system-bar>
    <v-data-table
      :headers="headers"
      :items="itemsFiltered"
      sort-by="code"
      :items-per-page="-1"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.feedbacks="{ item }">
        <v-tooltip
          top
          v-for="feedback in item.feedbacks"
          :key="item.code + '_' + feedback.id"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip small dark v-bind="attrs" v-on="on">
              <v-progress-circular
                color="success"
                size="16"
                width="2"
                :value="feedback.percentage"
                class="mr-2"
              ></v-progress-circular>
              {{ feedback.person.code }}
            </v-chip>
          </template>
          <span>
            <PersonName :value="feedback.person" />, {{ feedback.description }}:
            {{ feedback.percentage }} %</span
          >
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PersonName from "common/components/PersonName";
import { searchPerson } from "common/utils/people";
export default {
  components: { PersonName },
  props: ["search", "period"],
  data() {
    return {
      headers: [
        {
          text: "Klasse",
          value: "code",
          sortable: false,
        },
        { text: "Feedbacks", value: "feedbacks", sortable: false },
      ],
      items: [],
      loading: false,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          item.feedbacks.some(
            (el) => !this.search || searchPerson(el.person, this.search)
          ) ||
          (this.search &&
            item.code.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
  },
  watch: {
    period() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const feedbacks = await this.apiList({
        resource: "quality/feedback",
        query: this.period ? "period=" + this.period.id : "",
      });
      this.items = [];
      feedbacks.forEach((feedback) => {
        if (feedback.schoolClasses) {
          feedback.schoolClasses.forEach((schoolClass) => {
            const entry = this.items.find((el) => el.code == schoolClass.code);
            if (entry) {
              entry.feedbacks.push({
                id: feedback.id,
                person: feedback.person,
                percentage: schoolClass.percentage,
                description: feedback.description,
              });
            } else {
              this.items.push({
                code: schoolClass.code,
                feedbacks: [
                  {
                    id: feedback.id,
                    person: feedback.person,
                    percentage: schoolClass.percentage,
                    description: feedback.description,
                  },
                ],
              });
            }
          });
        }
      });
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
